<script>
import DatafileReportService from "@/api/services/datafileReport.service.js";
import appConfig from "@/app.config";
import i18n from "@/i18n";
import LoadingCircle from "@/shared/components/model/loading-circle.vue";

export default {
  page: {
    title: "Report Verification",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    LoadingCircle
  },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        }
      ],
      lan: i18n.locale,
      certificationCode: null,
      certificateVerificationResponse: null,
      /****** Operational Variables *****/
      isValidCertificate: true,
      isLoading: false,
      isMobile: false
    };
  },
  async created() {
    await this.detectMobile();
    this.certificationCode = this.$route.query.certificateCode;
    await this.checkCertificateVerification(this.certificationCode);
  },
  methods: {
    clickViewMoreButton() {
      this.$router.push({
        path: "datafile-report",
        query: { datafileId: this.certificateVerificationResponse.datafileId }
      });
    },
    detectMobile() {
      if (window.innerWidth <= 900) {
        this.isMobile = true;
      }
    },
    setLanguage(locale) {
      this.lan = locale;
      i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
    },
    clickBack() {
      window.location.href = "https://redem.io/quality-mark/";
    },
    async checkCertificateVerification(certificateCode) {
      this.isLoading = true;
      this.certificateVerificationResponse =
        await DatafileReportService.getCertificateVerification(certificateCode);

      if (this.certificateVerificationResponse) {
        this.isValidCertificate =
          this.certificateVerificationResponse.isVerified;
      }
      this.isLoading = false;
    }
  }
};
</script>

<template>
  <div class="p-3">
    <!-- Loading Component -->
    <LoadingCircle v-if="isLoading" />

    <!-- Success Component -->
    <div
      class="row justify-content-center"
      v-if="!isLoading && isValidCertificate"
    >
      <!-- Metadata Card -->
      <div class="col-lg-5">
        <div class="card" v-if="!this.isMobile" :style="{ height: '300px' }">
          <div class="card-body">
            <div class="card-titel">
              <h3>
                <strong>
                  {{ this.certificateVerificationResponse.datafileTitle }}
                </strong>
              </h3>
            </div>
            <hr />
            <div class="card-text">
              <div class="row">
                <!-- Comopany -->
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.uploadedBy") }}</div>
                  <div>
                    <strong>{{
                      this.certificateVerificationResponse.companyName
                    }}</strong>
                  </div>
                </div>
                <!-- Client -->
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.createdFor") }}</div>
                  <div v-if="this.certificateVerificationResponse.clientName">
                    <strong>{{
                      this.certificateVerificationResponse.clientName
                    }}</strong>
                  </div>
                  <div v-if="!this.certificateVerificationResponse.clientName">
                    <strong>-</strong>
                  </div>
                </div>
                <!-- Created Date -->
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.creationDate") }}:</div>
                  <div>
                    <strong>{{
                      new Date(
                        this.certificateVerificationResponse.createdDate
                      ).toLocaleDateString("de-DE")
                    }}</strong>
                  </div>
                </div>
                <!-- Certificate Level -->
                <div class="col-md-6 mb-3">
                  <div>
                    {{
                      $t(
                        "datafileResultCleanedTab.reportWidget.certificateLevel"
                      )
                    }}:
                  </div>
                  <div>
                    <b-badge style="font-size: 12px" variant="gold">
                      {{
                        this.certificateVerificationResponse.certificateLevel
                      }}
                    </b-badge>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <b-button variant="success" @click="clickViewMoreButton">
                    {{
                      $t(
                        "datafileResultCleanedTab.reportWidget.viewReportbutton"
                      )
                    }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="this.isMobile">
          <div class="card-body">
            <div class="card-titel">
              <h3>
                <strong>
                  {{ this.certificateVerificationResponse.datafileTitle }}
                </strong>
              </h3>
            </div>
            <hr />
            <div class="card-text">
              <div class="row">
                <!-- Comopany -->
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.uploadedBy") }}</div>
                  <div>
                    <strong>{{
                      this.certificateVerificationResponse.companyName
                    }}</strong>
                  </div>
                </div>
                <!-- Client -->
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.createdFor") }}</div>
                  <div v-if="this.certificateVerificationResponse.clientName">
                    <strong>{{
                      this.certificateVerificationResponse.clientName
                    }}</strong>
                  </div>
                  <div v-if="!this.certificateVerificationResponse.clientName">
                    <strong>-</strong>
                  </div>
                </div>
                <!-- Created Date -->
                <div class="col-md-6 mb-3">
                  <div>{{ $t("datafileReport.creationDate") }}</div>
                  <div>
                    <strong>{{
                      new Date(
                        this.certificateVerificationResponse.createdDate
                      ).toLocaleDateString("de-DE")
                    }}</strong>
                  </div>
                </div>
                <!-- Certificate Level -->
                <div class="col-md-6 mb-3">
                  <div>
                    {{
                      $t(
                        "datafileResultCleanedTab.metadataWidget.certificateLevel"
                      )
                    }}
                  </div>
                  <div>
                    <b-badge style="font-size: 12px" variant="gold">
                      {{
                        this.certificateVerificationResponse.certificateLevel
                      }}
                    </b-badge>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-12">
                  <b-button variant="success" @click="clickViewMoreButton">
                    {{ $t("datafileResultCleanedTab.reportWidget.viewReport") }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Certificate Card -->
      <div class="col-lg-3">
        <div class="card" v-if="!this.isMobile" :style="{ height: '300px' }">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <img
                  src="@/assets/images/redem-certification.png"
                  alt
                  class="img-fluid"
                  style="width: 200px"
                />
              </div>
              <div class="col-12 mt-3 text-center">
                <h3>
                  <strong>
                    {{
                      this.certificateVerificationResponse.certificateCode
                    }}</strong
                  >
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="this.isMobile">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <img
                  src="@/assets/images/redem-certification.png"
                  alt
                  class="img-fluid"
                  style="width: 200px"
                />
              </div>
              <div class="col-12 mt-3 text-center">
                <h3>
                  <strong>
                    {{
                      this.certificateVerificationResponse.certificateCode
                    }}</strong
                  >
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <div class="col-md-12 mt-4 text-center">
        <p>© {{ new Date().getFullYear() }} Redem GmbH</p>
        <!-- language Selector -->
        <div class="text-center">
          <span
            class="mr-2"
            @click="setLanguage(languages[0].language)"
            style="cursor: pointer"
          >
            <img class="mr-1" :src="languages[0].flag" height="10" />
            {{ languages[0].title }}
          </span>
          &bull;
          <span
            class="ml-2"
            @click="setLanguage(languages[1].language)"
            style="cursor: pointer"
          >
            <img class="mr-1" :src="languages[1].flag" height="10" />
            {{ languages[1].title }}
          </span>
        </div>
      </div>
    </div>

    <!-- Error Component -->
    <div
      class="row justify-content-center"
      v-if="!isLoading && !isValidCertificate"
    >
      <div class="col-lg-6 text-center">
        <div class="card">
          <div class="card-body">
            <img
              src="@/assets/images/red-warning.png"
              alt
              class="img-fluid mb-4"
              style="width: 60px"
            />
            <br />
            {{ $t("datafileReport.certificateVerification.notFoundMsg") }}
            <div class="row mt-3">
              <div class="col-12">
                <b-button variant="light" @click="clickBack()">
                  {{ $t("datafileReport.certificateVerification.backButton") }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <!-- Footer -->
        <div class="col-md-12 mt-4 text-center">
          <p>© {{ new Date().getFullYear() }} Redem GmbH</p>
          <!-- language Selector -->
          <div class="text-center">
            <span
              class="mr-2"
              @click="setLanguage(languages[0].language)"
              style="cursor: pointer"
            >
              <img class="mr-1" :src="languages[0].flag" height="10" />
              {{ languages[0].title }}
            </span>
            &bull;
            <span
              class="ml-2"
              @click="setLanguage(languages[1].language)"
              style="cursor: pointer"
            >
              <img class="mr-1" :src="languages[1].flag" height="10" />
              {{ languages[1].title }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
